import { useState } from "react";
import pageNotFound from "../public/Images/pageNotFound.png";
import Image from "next/image";
import Link from "next/link";

export default function Custom404() {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 xl:grid-cols-12 xl:gap-7 xs:gap-0">
          <div className="xl:col-span-8 xs:col-span-12 sm:col-span-12 text-center">
            <Image
              src={pageNotFound}
              className="block mx-auto"
              width="554"
              height="363"
              alt="404 page"
            />
          </div>
          <div className="xl:col-span-4 xs:col-span-12 sm:col-span-12 text-center">
            <h1 className="md:text-200 font-bold mb-4 text-green-1 xl:leading-200 xs:text-160 xs:leading-160">404</h1>

            <span className="text-2xl leading-9 ">
              Oops! That page cant be found
            </span>

            <div className="mt-4 text-center">
              <a href={process.env.NEXT_PUBLIC_WEB_DOMAIN || "/"} className="inline-flex items-center justify-center sm:justify-evenly h-10 text-sm  text-white font-semibold bg-gradient-to-r from-red-500 to-pink-500 rounded sm:py-2 sm:px-1.5 p-4"> Go back to home page</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
